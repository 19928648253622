<template>
  <div class="w-100 pt-4">
    <AsyncList
      :is-all-items-loaded="isAllItemsLoaded"
      without-loader
      class="h-100"
      @load-more-items="loadMoreItems"
    >
      <b-container
        fluid="xl"
      >
        <div class="d-flex align-items-center mb-3">
          <h3 class="title mb-0">
            История импорта прайсов
          </h3>
        </div>

        <div
          v-if="!totalCount && !isLoading"
          class="px-3"
        >
          Ничего не найдено
        </div>

        <b-table
          v-if="totalCount"
          class="bg-white m-0"
          bordered
          :fields="fields"
          :items="items"
        >
          <template #cell(createdAt)="row">
            {{ formatDate(row.item.createdAt) }}
          </template>

          <template #cell(author)="row">
            {{ row.item.author.displayName }}
          </template>

          <template #cell(clinic)="row">
            {{ row.item.clinic.name }}
          </template>

          <template #cell(cloudKey)="row">
            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :disabled="isFileLoading"
              @click="saveImportFile(row.item.cloudKey, row.item.originalName)"
            >
              Скачать
            </b-button>
          </template>
          <template #cell(fileErrorCloudKey)="row">
            <b-button
              v-if="row.item.fileErrorCloudKey"
              variant="warning"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              :disabled="isFileLoading"
              @click="saveImportFile(row.item.fileErrorCloudKey, row.item.originalFileErrorName)"
            >
              Скачать
            </b-button>

            <template v-else>
              —
            </template>
          </template>
        </b-table>
        <b-skeleton-table
          v-if="isLoading"
          :rows="25"
          :columns="fields.length"
          :table-props="{ bordered: true, striped: true }"
        />
      </b-container>
    </AsyncList>
  </div>
</template>

<script>
import { format } from '@evd3v/date-fns';
import AsyncList from '@/components/AsyncList';
import { utc0ToDateWithUserTimezone } from '@/helpers/utils';
import { clinicService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'ClinicsHistory',

  components: {
    AsyncList,
  },

  data() {
    return {
      isLoading: false,
      isFileLoading: false,
      items: [],
      allCount: 0,
      take: 10,
      page: 1,
      fields: [
        {
          key: 'createdAt',
          label: 'Дата',

        },
        {
          key: 'author',
          label: 'Пользователь',
        },
        {
          key: 'clinic',
          label: 'Клиника',
        },
        {
          key: 'cloudKey',
          label: 'Файл',
          class: 'td-button',
        },
        {
          key: 'fileErrorCloudKey',
          label: 'Ошибка',
          class: 'td-button',
        },
      ],
    };
  },
  computed: {
    totalCount() {
      return this.items.length;
    },
    isAllItemsLoaded() {
      return this.totalCount >= this.allCount;
    },
  },
  async mounted() {
    await this.itemsFetch();
  },
  methods: {
    async loadMoreItems() {
      if (this.isLoading) return;

      this.page += 1;
      await this.itemsFetch();
    },
    async itemsFetch() {
      this.isLoading = true;
      try {
        const { paginatedCollection, originalCount } = await this.getImportClinicHistory();
        const newItems = paginatedCollection.map((item) => ({ ...item, createdAt: `${item.createdAt}` }));
        this.items.push(...newItems);
        this.allCount = originalCount;
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getImportClinicHistory() {
      try {
        const data = await clinicService.getImportHistory({
          take: this.take,
          skip: (this.page - 1) * this.take,
        });
        return data;
      } catch (err) {
        showErrorCustomMessage('Не удалось загрузить историю импорта');
        console.warn(err);
        throw err;
      }
    },
    formatDate(date) {
      const newDate = utc0ToDateWithUserTimezone(date);
      return format(new Date(newDate), 'dd.MM.yyyy HH:mm');
    },
    async saveImportFile(id, name) {
      this.isFileLoading = true;
      try {
        await clinicService.getFile({ id, name });
      } catch (err) {
        showErrorCustomMessage('Не удалось скачать файл');
        console.warn(err);
        throw err;
      }
      this.isFileLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
 ::v-deep.bg-white .td-button {
    width: 150px;
    text-align: center;
    vertical-align: inherit;
  }

  ::v-deep.bg-white thead th {
    border-bottom-width: 0px;
    vertical-align: inherit;
  }
</style>
